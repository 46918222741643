import React from "react";
import { useRouter } from "next/router";

import PageWrapper from "@/components/PageWrapper";
import Typography from "@/components/Typography";
import Container from "@/components/Container";
import { GridColumns } from "@/components/Container/Container.interface";
import WysiwygText from "@/components/WysiwygText";
import { apiClient } from "@/helpers/apiClient";
import { apiPath } from "@/helpers/apiPath";
import { PageInterface } from "@/helpers/pageHelpers/Page";
import MetaHead from "@/components/MetaHead";
import SkeletonLoader from "@/components/SkeletonLoader";
import { Schema } from "@/components/MetaHead/Head.interface";
import ErrorPage from "src/pages/_error";
import { getParsedJSON } from "@/helpers/getParsedJSON";

import styles from "./Page.module.scss";

export const Page: React.FC<PageInterface> = ({
  title,
  body,
  entities,
  headMetaData,
}) => {
  const router = useRouter();

  if (router.isFallback) {
    return (
      <PageWrapper>
        <div className={styles.top}>
          <Container>
            <Typography component="h1" variant="h1" className={styles.heading}>
              <SkeletonLoader height={50} />
            </Typography>
          </Container>
        </div>
        <Container grid={GridColumns.Three} className={styles.container}>
          <div className={styles.body}>
            <SkeletonLoader height={300} />
          </div>
        </Container>
      </PageWrapper>
    );
  }

  if (!body) {
    return (
      <ErrorPage statusCode={404} hasGetInitialPropsRun={false} err={404} />
    );
  }
  const { slug } = router.query;

  const pageData = {
    headerLabel: title,
  };
  const text = getParsedJSON(body);

  const schemaData = {
    type: Schema.Page,
    title,
    slug,
  };

  return (
    <PageWrapper pageData={pageData}>
      <MetaHead metaData={headMetaData} schemaData={schemaData} />

      <div className={styles.top}>
        <Container>
          <Typography component="h1" variant="h1" className={styles.heading}>
            {title}
          </Typography>
        </Container>
      </div>
      <Container grid={GridColumns.Three} className={styles.container}>
        <div className={styles.body}>
          <WysiwygText data={text} entities={entities} />
        </div>
      </Container>
    </PageWrapper>
  );
};

export async function getStaticPaths() {
  const client = apiClient();

  const { data: pages } = await client.get(apiPath.STATIC_PAGES);

  const paths =
    pages &&
    pages.map((page) => ({
      params: { slug: page.slug },
    }));

  return {
    paths,
    fallback: true,
  };
}

export async function getStaticProps(context) {
  const { params } = context;
  const client = apiClient();

  try {
    const { data } = await client.get(apiPath.PAGE(params.slug));
    return {
      props: {
        ...data,
      },
      revalidate: 86400,
    };
  } catch (e) {
    return {
      props: {
        initialData: {
          error: true,
          slug: params.slug,
        },
      },
      revalidate: 43200,
    };
  }
}

export default Page;
